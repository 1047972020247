* {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  height: 200vh;
  background-color: "#00000";
}
nav {
  position: fixed;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
}

.choose-effect {
  background: #000;
  /* transition: background 0.3s; */
}

.mons-font {
  font-family: 'Montserrat';
}
/* Hover state */
.choose-effect:hover {
  background: #255ba9; 
}

ul {
  list-style: none;
}

li {
  display: inline;
  margin: 0 1rem;
  color: #fff;
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr)); /* Adjust minmax value as needed for responsiveness */
  grid-gap: 1rem; /* Adjust the gap between icons */
  justify-items: center;
  padding: 1rem; /* Add padding to the grid container */
}


/* Existing styles for icons */
.icon {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 2s ease; /* Add a transition for smoother hover effect */
  cursor: pointer;
}




.icon img {
  max-width:110%; /* Adjust the maximum image size as needed */
  max-height:110%;
}


  

@media screen and (max-width: 480px) {
  .icon-grid {
    grid-template-columns: repeat(auto-fill, minmax(3rem, 1fr)); /* Adjust for smaller screens */
  }

}


.content{
  font-size: 18px;
  font-weight: 600;
  font-family: sans-serif;
  color: #3351a3;
  padding-bottom: 10;
}
.marquee {
  background-color: #f4dd56;
  position: relative;
  z-index: 1;
  width: 100vw;
  max-width: 100%;
  height: 36px;
  overflow-x: hidden;
}

.track {
  position: absolute;
  white-space: nowrap;
  margin-top: 4px;
  will-change: transform;
  animation: marquee 32s linear infinite;
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}

.custom-bg {
  background-color: #ffffff;
}

.custom-blue-color{
  color: #255ba9;
}

.arial-font{
  font-family: Arial, Helvetica, sans-serif;
}

.custom-blue-bg{
  background-color: #255ba9;
}

.slow-spin {
  animation-duration: 7s; /* Increase or decrease the duration as needed */
}


.btn {
  display: inline-block;
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin: 5px;
  font-size: 25px;
  color: gray;
  
  /*  Circles  */
  border-radius: 25px;
  background-color: white;

  /* center icons */
  text-align: center;
  line-height: 50px;
  
  /* remove touch blue highlight on mobile */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
